.ExperienceItem {
    width: 80%;

    display: flex;
    flex-direction: column;

    padding-bottom: 4%;
    transition: 0.5s ease;
}

.experience-item-top {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    border-bottom: solid;
    border-color:#7C6968;
    border-width: 1px;

    padding: 2%;
}

.experience-item-bottom {
    width: 100%;

    display: flex;
    flex-direction: row;
}

.experience-item-name {
    width: 60%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.experience-name-header {
    text-align: left;

    color: #7C6968;
    font-family: 'KaiseiOpti-Normal';
    font-style: italic;
    font-size: 1.5em;
}

.experience-role {
    text-align: left;

    color: #B8A1A1;
    font-family: 'Syne-Regular';
    font-weight: 600;
    font-size: 1.1em;

    padding-top: 2%;
}

.experience-item-description {
    width: 80%;
    text-align: left;

    color: #7C6968;
    font-family: 'Syne-Regular';
    font-style: normal;
    font-size: 1em;

    padding-left: 2%;
    padding-right: 6%;
}

.experience-item-bottom {
    width: 100%;
    display: flex;
}

.experience-timeline {
    width: 100%;
    text-align: left;

    color: #B8A1A1;
    font-family: "Syne-Regular";
    font-style: normal;
    font-size: 1em;
    line-height: 20px;

    padding: 0.6%;
    padding-left: 2%;
}