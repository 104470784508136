.ProjectItem {
    width: 80%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    z-index: 0;
    transition: 0.5s ease;
}

.project-item-main {
    width: 80%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.top {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    border-bottom: solid;
    border-color: #7C6968;
    border-width: 1px;

    padding: 2%;
}

.project-item-header {
    width: 60%;

    text-align: left;

    color: #7C6968;
    font-family: 'KaiseiOpti-Normal';
    font-style: italic;
    font-size: 1.5em;
}

.project-item-description {
    width: 80%;

    text-align: left;

    color: #7C6968;
    font-family: 'Syne-Regular';
    font-style: normal;
    font-size: 1em;

    padding-left: 2%;
    padding-right: 6%;
}

.skills {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    color: #B8A1A1;
    font-family: 'Syne-Regular';
    font-style: normal;
    font-size: 0.8em;
}

.bottom {
    height: 100%;
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: #7C6968;
    font-family: "Syne-Regular";
    font-style: normal;
    font-size: 1em;

    padding-left: 2%;
}

.ind-skill {
    border: solid;
    border-radius: 20px;
    border-width: 1px;

    padding: 0.6%;
    padding-left: 2%;
    padding-right: 2%;
    margin-right: 0.75%;
}

.ind-skill:hover {
    color: white;
    background-color: #7C6968;
    border-color: transparent;
}

.image1 {
    width: 25vw;
    height: auto;

    transition: 0.5s ease;
}

.image1:hover {
    transform: scale(1.08);
}

.image-div {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-bottom: 3%;
}