.Experience {
	width: 100vw;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	padding-top: 2%;
	z-index: 0;
}

.experience-header {
	width: 25%;
	padding-right: 68%;
}

.experience-list {
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	padding-top: 1%;
	padding-bottom: 5%;
	z-index: 0;
}