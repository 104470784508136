.About {
	width: 100vw;
}

/* home section */

.home {
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding-top: 5%;
	padding-bottom: 5%;
}

.intro {
	color: #B8A1A1;
	font-family: 'KaiseiOpti-Normal';
	font-style: bold;
	font-size: 3em;
	letter-spacing: -1px;
}

.intro-description {
	color: #B8A1A1;
	font-family: 'KaiseiOpti-Normal';
	font-weight: 1000;
	font-size: 1.7em;

	width: 25%;
	padding-top: 2%;
}

.about-profile-pic {
	width: 25%;
	height: auto;
	transition: 0.8s ease-in-out;

	padding-bottom: 2%;
}

.about-profile-pic:hover {
	transform: rotate(15deg);
}

.about-intro-pic {
	width: 30%;
	height: auto;

	filter: drop-shadow(3px 3px 2px rgb(236, 235, 235))
}

.pink-text {
	color: #EBCFC4;
}

.arrow {
	display: flex;
	justify-content: center;
}

.down-arrow {
	width: 20%;
	margin-top: 50%;
}

.down-arrow:hover {
	cursor: pointer;
}

/* mini scroller section */

.mini-section {
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	background-color: #f1dad871;

	padding-top: 1%;
	padding-bottom: 1%;
}

.intro-scroller {
	width: 19%;
	text-align: left;

	display: flex;
	flex-direction: row;
	justify-content: center;

	color: #B8A1A1;
	font-family: 'KaiseiOpti-Normal';
	font-style: normal;
	font-size: 2em;
	font-weight: bold;

	padding-left: 2%;
}

.scroller {
	width: 52%;
	height: 1.2em;
	line-height: 1.2em;

	position: relative;
	overflow: hidden;
}

.scroller>span {
	position: absolute;
	top: 0;

	font-weight: bold;
	animation: slide 8s infinite;
}

@keyframes slide {
	0% {
		top: 0;
	}

	25% {
		top: -1.2em;
	}

	50% {
		top: -2.4em;
	}

	75% {
		top: -3.6em;
	}
}

.stars {
	width: 7%;
	height: 10%;
}

.brown-text {
	color: #7C6968;
}

.bold-text {
	font-weight: bold;
}

/* about me section */

.about {
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: center;

	padding-top: 6%;
}

.big-about-box {
	width: 70%;

	background-color: #f1dad871;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border: solid;
	border-radius: 50px;
	border-color: #B8A1A1;
	border-width: 0px;

	padding-bottom: 5%;
	transition: 0.5s ease;
}

.left-about {
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: center;

	padding-bottom: 4%;
}

.right-about {
	width: 100%;

	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
}

.about-box {
	width: 20%;

	display: flex;
	flex-direction: column;
}

.about-header {
	width: 20%;
	max-width: 14rem;
	max-width: 50rem;

	padding: 1%;
	padding-top: 3%;
}

.box-header {
	color: #7C6968;
	font-family: "KaiseiOpti-Normal";
	font-style: normal;
	font-size: 1.5em;
}

.about-text {
	color: #B8A1A1;

	font-family: 'Syne-Regular';
	font-style: normal;
	font-size: 1em;
}

.computer-pic,
.healthcare-pic,
.music-pic {
	width: 15%;
	transition: 0.4s ease;
}

.computer-pic:hover,
.healthcare-pic:hover,
.music-pic:hover {
	transform: translateY(-7px);
}

.underline-text {
	color: #B8A1A1;
	font-family: 'Syne-Regular';
	font-style: normal;
	font-size: 1em;
	text-decoration: none;

	background-image: linear-gradient(120deg, #f9f9f6 0%, #f9f9f6 100%);
	background-repeat: no-repeat;
	background-size: 100% 0.2em;
	background-position: 0 88%;
	transition: background-size 0.25s ease-in;

	&:hover {
		background-size: 100% 88%;
	}
}

@media screen and (max-width: 780px) {
	.intro-description {
		color: #B8A1A1;
		font-family: 'KaiseiOpti-Normal';
		font-style: bold;
		font-size: 1em;
	
		width: 40%;
	}

	.mini-section {
		width: 100%;
	
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	
		background-color: #f1dad871;
	
		padding-top: 1%;
		padding-bottom: 1%;
	}
	
	.intro-scroller {
		width: 29%;
		text-align: left;
	
		display: flex;
		flex-direction: row;
		justify-content: center;
	
		color: #B8A1A1;
		font-family: 'KaiseiOpti-Normal';
		font-style: normal;
		font-size: 1.3em;
		font-weight: bold;
	}
	
	.scroller {
		width: 50%;
		height: 1.2em;
		line-height: 1.2em;
	
		position: relative;
		overflow: hidden;
	}
	
	.big-about-box {
		width: 85%;
	
		background-color: #f1dad871;
	
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	
		border: solid;
		border-radius: 50px;
		border-color: #B8A1A1;
		border-width: 0px;
	
		padding-bottom: 5%;
		transition: 0.5s ease;
	}

	.about-box {
		width: 40%;

		padding: 3%;
	}

	.box-header {
		color: #7C6968;
		font-family: "KaiseiOpti-Normal";
		font-style: normal;
		font-size: 1.2em;
	}
	
	.about-text {
		color: #B8A1A1;
	
		font-family: 'Syne-Regular';
		font-style: normal;
		font-size: 0.8em;
	}
}

@media screen and (max-width: 1024px) {
	.intro-description {
		color: #B8A1A1;
		font-family: 'KaiseiOpti-Normal';
		font-style: bold;
		font-size: 1.55em;
	
		width: 35%;
	}

	.mini-section {
		width: 100%;
	
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	
		background-color: #f1dad871;
	
		padding-top: 1%;
		padding-bottom: 1%;
	}
	
	.intro-scroller {
		width: 24%;
		text-align: left;
	
		display: flex;
		flex-direction: row;
		justify-content: center;
	
		color: #B8A1A1;
		font-family: 'KaiseiOpti-Normal';
		font-style: normal;
		font-size: 1.6em;
		font-weight: bold;
	}
	
	.scroller {
		width: 50%;
		height: 1.2em;
		line-height: 1.2em;
	
		position: relative;
		overflow: hidden;
	}

	.big-about-box {
		width: 85%;
	
		background-color: #f1dad871;
	
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	
		border: solid;
		border-radius: 50px;
		border-color: #B8A1A1;
		border-width: 0px;
	
		padding-bottom: 5%;
		transition: 0.5s ease;
	}

	.about-box {
		width: 40%;

		padding: 3%;
	}

	.box-header {
		color: #7C6968;
		font-family: "KaiseiOpti-Normal";
		font-style: normal;
		font-size: 1.4em;
	}
	
	.about-text {
		color: #B8A1A1;
	
		font-family: 'Syne-Regular';
		font-style: normal;
		font-size: 1em;
	}
}