.Footer {
    width: 100%;  

    display: flex;
    flex-direction: column;
}

.footer-1 {
    display: flex;
    justify-content: center;

    background-color: #f1dad871;
}

.footer-link {
    display: block; 
    text-align: center;

    color: #B8A1A1;
    font-family: "Syne-Regular";
    font-size: 1em;
    text-decoration: none;

    padding: 1.5%;
}

.footer-link:hover {
    color:#7C6968
}

.footer-2 {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: nowrap;

    color: #7C6968;
    text-decoration: none;
    font-family: 'KaiseiOpti-Normal';
    font-size: 0.85em;

    background-color: #dabebe;
}

.heart-icon {
    width: 7%;
}
