* {
    z-index: 9999999;
}

.Nav {
    width: 100%;

    top: 0;
    position: fixed;

    display: flex;
    flex-direction: row;
    justify-content: flex-between;
    align-items: center;

    z-index: 99999999;
}

.logo-image {
    width: 2%;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    backdrop-filter: blur(5px);
    border-radius: 15px;
    border: solid;
    border-color: transparent;

    margin-left: 2%;
    margin-top: 0.5%;
    padding: 0.5%;
}

ul {
    width: 100%;

    display: flex;
    justify-content: flex-end;

    list-style-type: none;

    margin: 0;
    padding: 0;
}

li a {
    display: block;
    text-align: right;

    color: #7C6968;
    text-decoration: none;
    font-family: "Syne-Regular";
    font-size: 1.2em;

    backdrop-filter: blur(5px);
    border-radius: 50px;
    border: solid;
    border-color: transparent;

    margin-left: 8px;
    margin-right: 8px;
    padding-left: 8px;
    padding-right: 8px;

    z-index: 9999999;
}

ul:hover li {
    opacity: .2;
    filter: blur(0.8px);

    transition: .5s ease-in-out;
}

ul li:hover {
    opacity: 1;
    filter: blur(0px);

    transition: .5s ease-in-out;
}

.active {
    text-decoration: underline wavy #EBCFC4;
}

@media screen and (max-width: 780px) {
    ul:hover li {
        opacity: 1;
        filter: blur(0px);
    
        transition: .5s ease-in-out;
    }

    ul li:hover {
        opacity: 1;
        filter: blur(0px);
    
        transition: .5s ease-in-out;
    }
}