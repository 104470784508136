.App {
	width: 100%;
	height: 100%;

	text-align: center;
	overflow-wrap: break-word;

	background-color: #f9f9f6;
	overflow-x: hidden;
	overflow-y: hidden;
}

.NavBar {
	display: flex;
	justify-content: space-between;

	z-index: 9999;
}