.Project {
	width: 100vw;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	padding-top: 2%;

	z-index: 0;
}

.project-header {
	width: 20%;
	padding-right: 72%;
}

.project-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	padding-bottom: 5%;
}