body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@import url("https://use.typekit.net/czt6wum.css");

@font-face {
	font-family: SpaceMono-Regular;
	src: url('/src/assets/Space_Mono/SpaceMono-Regular.ttf');
}

@font-face {
	font-family: SpaceMono-Bold;
	src: url('/src/assets/Space_Mono/SpaceMono-Bold.ttf');
}

@font-face {
	font-family: KaiseiOpti-Normal;
	src: url('/src/assets/KaiseiOpti-Normal.ttf');
}

@font-face {
	font-family: Syne-Regular;
	src: url('/src/assets/Syne/static/Syne-Regular.ttf');
}